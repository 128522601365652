import React from 'react';
import { Outlet } from 'react-router-dom';
import { HeaderWrapper } from './components/header';
import { ScrollTop } from './components/scroll-top';
import { Content } from './components/content';
import { FooterWrapper } from './components/footer';
import { Sidebar } from './components/sidebar';
import { PageDataProvider } from './core';
import { AxiosInterceptor } from '../../app/routing/AxiosInterceptors';

const MasterLayout = () => {
  return (
    <AxiosInterceptor>
      <PageDataProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>
        <ScrollTop />
      </PageDataProvider>
    </AxiosInterceptor>
  );
};

export { MasterLayout };
