import { FC, ReactNode } from 'react';
import { useAuth } from '../modules/auth';
import { Role } from '../config/consts';

interface IProps {
  allowedRoles: Role[];
  children: ReactNode;
}
const AccessTo: FC<IProps> = ({ allowedRoles, children }) => {
  const { currentUser } = useAuth();
  if (allowedRoles.some((role: Role) => currentUser?.role === role)) {
    return <>{children}</>;
  }

  return <></>;
};

export default AccessTo;
