import React from 'react';
import clsx from 'clsx';

type Props = {
  children?: any;
  className?: string;
  scroll?: boolean;
  height?: number;
};

const KTCardBody = (props: Props) => {
  const { className, scroll, height, children } = props;
  return (
    <div
      className={clsx(
        'card-body',
        className && className,
        {
          'card-scroll': scroll,
        },
        height && `h-${height}px`
      )}
    >
      {children}
    </div>
  );
};

export { KTCardBody };
