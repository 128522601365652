import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { FC } from 'react';
import { Role } from '../config/consts';
import { useAuth } from '../modules/auth';

interface IProp {
  allowedRoles: Role[];
}

const RouteGaurd: FC<IProp> = ({ allowedRoles }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  return allowedRoles.find(role => currentUser?.role === role) ? (
    <Outlet />
  ) : currentUser?.email ? (
    <Navigate to='/' state={{ from: location }} replace />
  ) : (
    <Navigate to='/auth' state={{ from: location }} replace />
  );
};

export default RouteGaurd;
