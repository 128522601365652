const TopUpCashActiveIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.6003 12.6049H21.5664V11.2033C21.5664 9.18843 19.9215 7.54346 17.9066 7.54346H6.73256C4.71772 7.54346 3.07275 9.18843 3.07275 11.2033V15.4568C3.74437 14.9215 4.59118 14.6003 5.50613 14.6003C7.65724 14.6003 9.39954 16.3425 9.39954 18.4937C9.39954 19.2237 9.19513 19.9147 8.83499 20.4988C8.63059 20.8492 8.36778 21.1606 8.06604 21.4137H17.9066C19.9215 21.4137 21.5664 19.7687 21.5664 17.7539V16.5956H19.7171C18.6658 16.5956 17.7022 15.8267 17.6146 14.7754C17.5562 14.1622 17.7898 13.588 18.1986 13.1889C18.5588 12.819 19.0552 12.6049 19.6003 12.6049Z'
        fill='#B2B7C5'
      />
      <path
        d='M15.0936 3.8449V7.54362H6.73256C4.71772 7.54362 3.07275 9.1886 3.07275 11.2034V7.63125C3.07275 6.47296 3.7833 5.44117 4.86372 5.03236L12.5921 2.11231C13.7991 1.66457 15.0936 2.55035 15.0936 3.8449Z'
        fill='#172548'
      />
      <path
        d='M22.5989 13.5979V15.603C22.5989 16.1384 22.1706 16.5764 21.6255 16.5958H19.7178C18.6665 16.5958 17.7029 15.8269 17.6153 14.7757C17.5569 14.1624 17.7905 13.5882 18.1993 13.1891C18.5595 12.8192 19.0559 12.6051 19.601 12.6051H21.6255C22.1706 12.6246 22.5989 13.0625 22.5989 13.5979Z'
        fill='#172548'
      />
      <path
        d='M14.2664 12.4102H7.45291C7.05384 12.4102 6.7229 12.0793 6.7229 11.6802C6.7229 11.2811 7.05384 10.9502 7.45291 10.9502H14.2664C14.6654 10.9502 14.9964 11.2811 14.9964 11.6802C14.9964 12.0793 14.6654 12.4102 14.2664 12.4102Z'
        fill='#172548'
      />
      <path
        d='M8.25913 15.7407C7.61216 15.0938 6.78624 14.7221 5.93279 14.6257C4.79716 14.495 3.62023 14.8735 2.75302 15.7407C2.23682 16.2569 1.8927 16.8901 1.7344 17.5577C1.41091 18.831 1.74816 20.242 2.75302 21.2468C3.44817 21.942 4.33602 22.3205 5.25141 22.3687C5.64372 22.4031 6.0498 22.3687 6.44211 22.2655C7.10973 22.1072 7.74293 21.763 8.25913 21.2468C9.78019 19.7258 9.78019 17.2618 8.25913 15.7407ZM4.79026 17.0347C4.79026 16.6355 5.12063 16.3051 5.51982 16.3051C5.9259 16.312 6.24938 16.6355 6.25626 17.0416L6.2494 17.7642L6.94456 17.7573C7.35063 17.7642 7.67412 18.0877 7.681 18.4938C7.67411 18.8998 7.35063 19.2233 6.94455 19.2302L6.2494 19.2233L6.25627 19.946C6.24939 20.3521 5.9259 20.6756 5.51983 20.6825C5.32023 20.6756 5.14131 20.593 5.01054 20.4622C4.87977 20.3314 4.79715 20.1525 4.79027 19.9529L4.79029 19.2233H4.03321C3.82673 19.2233 3.64776 19.1407 3.51699 19.01C3.38622 18.8792 3.30365 18.7002 3.30365 18.4938C3.30365 18.0946 3.63401 17.7642 4.03321 17.7642H4.79028L4.79026 17.0347Z'
        fill='#172548'
      />
    </svg>
  );
};

export { TopUpCashActiveIcon };
