import React from 'react';
import { SidebarMenuItem } from './SidebarMenuItem';
import AccessTo from '../../../../../app/guards/ComponentGuard';
import { Role } from '../../../../../app/config/consts';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import SidebarCustomMenuItem from './SidebarCustomMenuItem';
import { DistributorsActiveIcon } from '../../../../assets/images/distributors-active-icon';
import { DistributorsInactiveIcon } from '../../../../assets/images/distributors-inactive-icon';
import { SweepFundsInActiveIcon } from '../../../../assets/images/sweep-funds-inactive-icon';
import { SweepFundsActiveIcon } from '../../../../assets/images/sweep-funds-active-icon';
import { TopUpCashActiveIcon } from '../../../../assets/images/top-up-cash-active-icon';
import { TopUpCashInActiveIcon } from '../../../../assets/images/top-up-cash-inactive-icon';
import { AssignCardInActiveIcon } from '../../../../assets/images/assign-card-inactive-icon';
import { AssignCardActiveIcon } from '../../../../assets/images/assign-card-active-icon';
import { AccountsActiveIcon } from '../../../../assets/images/accounts-active-icon';
import { AccountsInactiveIcon } from '../../../../assets/images/accounts-inactive-icon';

const SidebarMenuMain = () => {
  return (
    <>
      <AccessTo allowedRoles={[Role.ADMIN]}>
        <SidebarMenuItem to='/dashboard' icon='element-11' fontIcon='bi-layers' title='Dashboard' />
        <SidebarCustomMenuItem
          to='/distributors'
          title='Distributors'
          activeIcon={<DistributorsActiveIcon />}
          inactiveIcon={<DistributorsInactiveIcon />}
        />
        <SidebarMenuItemWithSub
          to='/configurations'
          title='Configurations'
          icon='setting-2'
          fontIcon='bi-app-indicator'
        >
          <SidebarMenuItem to='/configurations/system' title='System' hasBullet={true} />
          <SidebarMenuItem to='/configurations/threshold' title='Threshold' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/card-management' icon='wallet' title='Card Management' fontIcon='bi-app-indicator' />
        <SidebarCustomMenuItem
          to='/sweep-funds'
          activeIcon={<SweepFundsActiveIcon />}
          inactiveIcon={<SweepFundsInActiveIcon />}
          title='Sweep Funds'
        />
        <SidebarMenuItem to='/membership-tiers' icon='badge' title='Membership Tiers' fontIcon='bi-app-indicator' />
        <SidebarCustomMenuItem
          to='/accounts'
          activeIcon={<AccountsActiveIcon />}
          inactiveIcon={<AccountsInactiveIcon />}
          title='Accounts'
        />
      </AccessTo>

      <AccessTo allowedRoles={[Role.DISTRIBUTOR]}>
        <SidebarCustomMenuItem
          to='/user'
          activeIcon={<AssignCardActiveIcon />}
          inactiveIcon={<AssignCardInActiveIcon />}
          title='Assign card'
        />

        <SidebarCustomMenuItem
          to='/topup-cash'
          activeIcon={<TopUpCashActiveIcon />}
          inactiveIcon={<TopUpCashInActiveIcon />}
          title='Topup cash'
        />
      </AccessTo>
    </>
  );
};

export { SidebarMenuMain };
