const AssignCardInActiveIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.3'
        d='M18.73 5.21001H5.27C3.46403 5.21001 2 6.67404 2 8.48001V16.74C2 18.546 3.46403 20.01 5.27 20.01H18.73C20.536 20.01 22 18.546 22 16.74V8.48001C22 6.67404 20.536 5.21001 18.73 5.21001Z'
        fill='#A1A5B7'
      />
      <path d='M22 8.94H2V11.48H22V8.94Z' fill='#A1A5B7' />
    </svg>
  );
};

export { AssignCardInActiveIcon };
