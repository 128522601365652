import { FC, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive } from '../../../../helpers';
import { useLayout } from '../../../core';
type Props = {
  to: string;
  title: string;
  activeIcon?: ReactNode;
  inactiveIcon?: ReactNode;
};
const SidebarCustomMenuItem: FC<Props> = ({ to, title, activeIcon, inactiveIcon }) => {
  const { config } = useLayout();
  const { app } = config;
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link to={to}>
      <div className='menu-item font-14px'>
        <div
          className={clsx('menu-link without-sub me-0', { active: isActive })}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>{isHovered || isActive ? activeIcon : inactiveIcon}</span>
          )}
          <span className='menu-title'>{title}</span>
        </div>
      </div>
    </Link>
  );
};

export default SidebarCustomMenuItem;
