import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik';
import { updatePIN } from '../core/_requests';
import { useSearchParams } from 'react-router-dom';
import { DIGITS_REGEX, sortByRulesConsecutiveRepeatedDigits, validateTokenLocally } from '../../../config/consts';
import { IPinPayload } from '../core/_models';

const cardPINSchema = Yup.object().shape({
  pin: Yup.string()
    .min(4, 'Minimum 4 digits')
    .max(12, 'Maximum 12 digits')
    .matches(DIGITS_REGEX, 'PIN must contain only digits')
    .test('no-consecutive-digits', "Can't have three or more in sequence (eg. 123674)", value => {
      if (!value) return true;
      return sortByRulesConsecutiveRepeatedDigits(value);
    })
    .test('repeated-digits', "Can't have three or more repeat in a row (111441)", value => {
      if (!value) return true;
      return sortByRulesConsecutiveRepeatedDigits(value);
    })
    .required('PIN is required'),
  confirmPin: Yup.string()
    .oneOf([Yup.ref('pin')], 'PIN must match')
    .required('Confirm PIN is required'),
});

const initialValues = {
  pin: '',
  confirmPin: '',
};

export const UpdateCardPin = () => {
  const [searchParams] = useSearchParams(),
    [displaySuccessMsg, setDisplaySuccessMsg] = useState(false),
    token = searchParams.get('token'),
    [showPin, setShowPin] = useState(false),
    [showConfirmPin, setShowConfirmPin] = useState(false),
    toggleShowPin = () => setShowPin(!showPin),
    toggleShowConfirmPin = () => setShowConfirmPin(!showConfirmPin);

  const handleSubmit = async (values: IPinPayload, { setErrors }: FormikHelpers<IPinPayload>) => {
    try {
      if (token && values?.pin) {
        const resToken = validateTokenLocally(token);
        if (resToken) {
          const res = await updatePIN({ token, pin: values?.pin.toString() });
          if (res.success) {
            setDisplaySuccessMsg(true);
          } else {
            setErrors({ pin: res.message });
          }
        } else {
          setErrors({ pin: 'The link has expired.' });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='container d-flex align-items-center justify-content-center min-vh-100'>
      <div className='row justify-content-center w-100'>
        <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 rounded shadow-sm '>
          {!displaySuccessMsg ? (
            <>
              <Formik initialValues={initialValues} validationSchema={cardPINSchema} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                  <Form className='form w-100 p-10 pb-5 ' id='kt_pin_update_form'>
                    <div className='text-center mb-11'>
                      <h1 className='text-dark fw-bolder mb-3'>PIN Update</h1>
                    </div>

                    <div className='fv-row mb-8'>
                      <label className='form-label fs-6 fw-bolder text-dark'>PIN</label>
                      <div className='input-container position-relative d-flex align-items-center'>
                        <Field
                          type={showPin ? 'text' : 'password'}
                          name='pin'
                          className='form-control form-control-lg form-control-solid mb-lg-0 input-border pin-input'
                          placeholder='Enter PIN'
                          autoComplete='off'
                        />
                        <span
                          data-kt-password-meter-control='visibility'
                          onClick={toggleShowPin}
                          className='eye-icon cursor-pointer d-flex align-items-center'
                        >
                          {showPin ? (
                            <i className='bi bi-eye-slash app-text-color fs-3'></i>
                          ) : (
                            <i className='bi bi-eye app-text-color fs-3'></i>
                          )}
                        </span>
                      </div>
                      <ErrorMessage name='pin' component='div' className='fv-plugins-message-container'>
                        {msg => <div className='fv-help-block text-danger'>{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className='fv-row mb-8'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Confirm PIN</label>
                      <div className='input-container position-relative d-flex align-items-center'>
                        <Field
                          type={showConfirmPin ? 'text' : 'password'}
                          name='confirmPin'
                          className='form-control form-control-lg form-control-solid mb-lg-0 input-border pin-input'
                          placeholder='Confirm PIN'
                          autoComplete='off'
                        />
                        <span
                          data-kt-password-meter-control='visibility'
                          onClick={toggleShowConfirmPin}
                          className='eye-icon cursor-pointer d-flex align-items-center'
                        >
                          {showConfirmPin ? (
                            <i className='bi bi-eye-slash app-text-color fs-3'></i>
                          ) : (
                            <i className='bi bi-eye app-text-color fs-3'></i>
                          )}
                        </span>
                      </div>
                      <ErrorMessage name='confirmPin' component='div' className='fv-plugins-message-container'>
                        {msg => <div className='fv-help-block text-danger'>{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className='fv-row mb-8'>
                      <ul className=''>
                        <li className='text-primary fw-semibold fs-6 me-2'>Only 4-12 digits accepted</li>
                        <li className='text-primary fw-semibold fs-6 me-2'>
                          Only two non-consecutive numbers accepted at most (eg. 124758)
                        </li>
                        <li className='text-primary fw-semibold fs-6 me-2'>
                          Only two repeated numbers in a row accepted at most (112441)
                        </li>
                      </ul>
                    </div>

                    <div className='d-grid mb-10'>
                      <button type='submit' id='kt_pin_submit' className='btn btn-primary' disabled={isSubmitting}>
                        {!isSubmitting && <span className='indicator-label'>Update</span>}
                        {isSubmitting && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                          </span>
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <div className='text-center mb-1 pt-10'>
                <h2 className='text-dark fw-bolder'>PIN updated successfully.</h2>
              </div>

              <div className='mb-10 bg-light-info p-10 m-10  rounded text-center'>
                <div className='text-info'>PIN updated successfully. You may now close this window.</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
