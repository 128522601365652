const TopUpCashInActiveIcon = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.9971 12.6049H21.9632V11.2033C21.9632 9.18843 20.3183 7.54346 18.3034 7.54346H7.12935C5.11451 7.54346 3.46954 9.18843 3.46954 11.2033V15.4568C4.14116 14.9215 4.98797 14.6003 5.90292 14.6003C8.05403 14.6003 9.79633 16.3425 9.79633 18.4937C9.79633 19.2237 9.59192 19.9147 9.23178 20.4988C9.02738 20.8492 8.76457 21.1606 8.46283 21.4137H18.3034C20.3183 21.4137 21.9632 19.7687 21.9632 17.7539V16.5956H20.1139C19.0626 16.5956 18.099 15.8267 18.0114 14.7754C17.953 14.1622 18.1866 13.588 18.5954 13.1889C18.9556 12.819 19.452 12.6049 19.9971 12.6049Z'
        fill='#D3D7DF'
      />
      <path
        d='M15.4904 3.8449V7.54362H7.12935C5.11451 7.54362 3.46954 9.1886 3.46954 11.2034V7.63125C3.46954 6.47296 4.18009 5.44117 5.26051 5.03236L12.9889 2.11231C14.1959 1.66457 15.4904 2.55035 15.4904 3.8449Z'
        fill='#A1A5B7'
      />
      <path
        d='M22.9957 13.5979V15.603C22.9957 16.1384 22.5674 16.5764 22.0223 16.5958H20.1146C19.0633 16.5958 18.0997 15.8269 18.0121 14.7757C17.9537 14.1624 18.1873 13.5882 18.5961 13.1891C18.9563 12.8192 19.4527 12.6051 19.9978 12.6051H22.0223C22.5674 12.6246 22.9957 13.0625 22.9957 13.5979Z'
        fill='#A1A5B7'
      />
      <path
        d='M14.6632 12.4102H7.8497C7.45063 12.4102 7.11969 12.0793 7.11969 11.6802C7.11969 11.2811 7.45063 10.9502 7.8497 10.9502H14.6632C15.0622 10.9502 15.3932 11.2811 15.3932 11.6802C15.3932 12.0793 15.0622 12.4102 14.6632 12.4102Z'
        fill='#A1A5B7'
      />
      <path
        d='M8.65592 15.7407C8.00895 15.0938 7.18303 14.7221 6.32958 14.6257C5.19395 14.495 4.01702 14.8735 3.14981 15.7407C2.63361 16.2569 2.28949 16.8901 2.13119 17.5577C1.8077 18.831 2.14495 20.242 3.14981 21.2468C3.84496 21.942 4.73281 22.3205 5.6482 22.3687C6.04051 22.4031 6.44659 22.3687 6.8389 22.2655C7.50652 22.1072 8.13972 21.763 8.65592 21.2468C10.177 19.7258 10.177 17.2618 8.65592 15.7407ZM5.18705 17.0347C5.18705 16.6355 5.51742 16.3051 5.91661 16.3051C6.32269 16.312 6.64617 16.6355 6.65305 17.0416L6.64619 17.7642L7.34135 17.7573C7.74742 17.7642 8.07091 18.0877 8.07779 18.4938C8.0709 18.8998 7.74742 19.2233 7.34134 19.2302L6.64619 19.2233L6.65306 19.946C6.64618 20.3521 6.32269 20.6756 5.91662 20.6825C5.71702 20.6756 5.5381 20.593 5.40733 20.4622C5.27656 20.3314 5.19394 20.1525 5.18706 19.9529L5.18708 19.2233H4.43C4.22352 19.2233 4.04455 19.1407 3.91378 19.01C3.78301 18.8792 3.70044 18.7002 3.70044 18.4938C3.70044 18.0946 4.0308 17.7642 4.43 17.7642H5.18707L5.18705 17.0347Z'
        fill='#A1A5B7'
      />
    </svg>
  );
};

export { TopUpCashInActiveIcon };
