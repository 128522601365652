import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import RouteGaurd from '../guards/RouteGuard';
import { Role } from '../config/consts';
import { useAuth } from '../modules/auth';

const PrivateRoutes = () => {
  const DistributorPage = lazy(() => import('../pages/distributor/DistributorWrapper')),
    UserPage = lazy(() => import('../modules/user/components/User')),
    ConfigurationsPage = lazy(() => import('../pages/configurations/ConfigurationsWrapper')),
    MembershipTiersPage = lazy(() => import('../pages/membershipTiers/MembershipTiers')),
    TopUpCashPage = lazy(() => import('../../app/modules/topUpCash/components/TopUpCash')),
    CardManagementPage = lazy(() => import('../../app/modules/cardManagement/components/cardManagement')),
    SweepFundsPage = lazy(() => import('../../app/modules/sweepFunds/components/SweepFunds')),
    AccountsPage = lazy(() => import('../pages/accounts/AccountWrapper'));

  const { currentUser } = useAuth();
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='auth/*'
          element={<Navigate to={currentUser?.role == Role.DISTRIBUTOR ? '/user' : '/dashboard'} />}
        />
        <Route element={<RouteGaurd allowedRoles={[Role.ADMIN]} />}>
          <Route path='/dashboard' element={<DashboardWrapper />} />

          <Route
            path='/distributors/*'
            element={
              <SuspensedView>
                <DistributorPage />
              </SuspensedView>
            }
          />
          <Route
            path='/configurations/*'
            element={
              <SuspensedView>
                <ConfigurationsPage />
              </SuspensedView>
            }
          />
          <Route
            path='/membership-tiers/*'
            element={
              <SuspensedView>
                <MembershipTiersPage />
              </SuspensedView>
            }
          />

          <Route
            path='/card-management'
            element={
              <SuspensedView>
                <CardManagementPage />
              </SuspensedView>
            }
          />
          <Route
            path='/sweep-funds'
            element={
              <SuspensedView>
                <SweepFundsPage />
              </SuspensedView>
            }
          />
          <Route
            path='/accounts/*'
            element={
              <SuspensedView>
                <AccountsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route element={<RouteGaurd allowedRoles={[Role.DISTRIBUTOR]} />}>
          <Route
            path='/user'
            element={
              <SuspensedView>
                <UserPage />
              </SuspensedView>
            }
          />
          <Route
            path='/topup-cash'
            element={
              <SuspensedView>
                <TopUpCashPage />
              </SuspensedView>
            }
          />
        </Route>

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView = ({ children }: any) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
