import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getUserByToken, loginConfirm } from '../core/_requests';
import { useAuth } from '../core/Auth';
import { Spinner } from '../../../../_metronic/helpers/components/Spinner';
import { parseJwt } from '../../../../_metronic/helpers/CommonHelper';

export const LoginConfirm = () => {
  const [loading, setLoading] = useState(false);
  const [displayLinkExpire, setDisplayLinkExpire] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const auth = await loginConfirm(token);
        if (auth) {
          saveAuth(auth);
          const resUser = await getUserByToken(auth?.accessToken);
          if (resUser) {
            const userInfo = parseJwt();
            if (userInfo) {
              resUser.data.data.role = userInfo.roles;
              setCurrentUser(resUser?.data?.data);
            }
          }
          setLoading(false);
        }
      } catch (error: any) {
        saveAuth(undefined);
        if (error?.response?.data?.error?.statusCode === 401) {
          setDisplayLinkExpire(true);
        }

        setLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <>
      <div className='text-center'>
        {loading ? (
          <div className='row justify-content-center'>
            -
            <Spinner className='fs-4' />
          </div>
        ) : (
          displayLinkExpire && (
            <>
              <h1 className='fw-bolder text-danger'>Your sign in link has expired</h1>
              <div
                className=' bg-card-info p-8 rounded cursor-pointer'
                onClick={() => navigate(`/auth`, { replace: true })}
              >
                <div className='text-info'>
                  Your sign in link has expired. Click here to resend the login information.
                </div>
              </div>
            </>
          )
        )}
      </div>
    </>
  );
};
