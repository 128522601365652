import React from 'react';
import clsx from 'clsx';
const itemClass = 'ms-1 ms-lg-3';

const Navbar = () => {
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}></div>
    </div>
  );
};

export { Navbar };
