import { AUTH_LOCAL_STORAGE_KEY } from '../../app/modules/auth';

const parseJwt = () => {
  const token: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY),
    base64Url = token?.split('.')[1],
    base64: string | undefined = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  if (base64) {
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
};

const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export { parseJwt, copyToClipboard };
