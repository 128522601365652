import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useEffect, ReactNode } from 'react';
import { REFRESH_TOKEN_URL, updateAccessToken } from '../modules/auth/core/_requests';
import { AUTH_LOCAL_STORAGE_KEY, useAuth } from '../modules/auth';

type Props = {
  children: ReactNode;
};

const AxiosInterceptor: React.FC<Props> = ({ children }) => {
  const { saveAuth } = useAuth();

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => {
      return response;
    };

    const errInterceptor = async (error: any) => {
      const originalRequest = error.config;

      if (error?.response?.status === 401 && originalRequest.url === REFRESH_TOKEN_URL) {
        window.location.href = '/auth';
        return Promise.reject(error);
      }

      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
        const isJson = (lsValue: any) => {
          try {
            JSON.parse(lsValue);
          } catch (e) {
            return false;
          }
          return true;
        };

        const refreshToken = lsValue && isJson(lsValue) === true && JSON.parse(lsValue)?.refreshToken;
        if (refreshToken) {
          const resp = await updateAccessToken(refreshToken);
          saveAuth(resp);
          return axios(originalRequest);
        } else {
          saveAuth(undefined);
          window.location.href = '/auth';
        }
      }
      return Promise.reject(error);
    };

    const responseInterceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return <>{children}</>;
};

export { AxiosInterceptor };
