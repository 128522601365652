import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { login } from '../core/_requests';
import { EMAIL_REGEX } from '../../../config/consts';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .matches(EMAIL_REGEX, 'Please enter a valid email address')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
});

const initialValues = {
  email: '',
};

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [emailSendingStatus, setEmailSendingStatus] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setLoading(true);
      try {
        const res = await login(values.email);
        if (res) {
          setEmailSendingStatus(true);
          setLoading(false);
        }
      } catch (error: any) {
        if (error?.response?.data?.error?.statusCode === 401) {
          setErrors({ email: 'Account is not found' });
        }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
      {!emailSendingStatus ? (
        <>
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Sign in with email</h1>
          </div>

          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div className='mb-10 bg-card-info p-8 rounded'>
              <div className='text-info'>
                Enter the email address associated with your account, and we’ll send a magic link to your inbox.
              </div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>

          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                </span>
              )}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Check your inbox.</h1>
          </div>

          <div className='mb-10 bg-card-info p-8 rounded'>
            <div className='text-info'>
              Please check your inbox at {formik?.values?.email} and click the link we sent to sign in.
            </div>
          </div>

          <div className='d-grid mb-10'>
            <button
              id='kt_sign_in_submit'
              className='btn btn-primary'
              onClick={() => {
                setEmailSendingStatus(false);
                formik?.resetForm();
              }}
            >
              <span className='indicator-label'>Ok</span>
            </button>
          </div>
        </>
      )}
    </form>
  );
};
