import { IPinPayload } from '../modules/cardPIN/core/_models';

const QUERIES = {
  DISTRIBUTOR: {
    LIST: 'distributor-list',
  },
  CURRENCY: {
    LIST: 'currency-list',
  },
  CARD: {
    VIEW_CARD: 'view-card',
  },
  SWEEP_FUND: {
    LIST: 'sweep-fund-list',
  },
  MEMBERSHIP_TIERS: {
    LIST: 'membership-tiers-list',
  },
  ACCOUNTS: {
    LIST: 'account-list',
  },
};

const EMAIL_REGEX: RegExp = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(?:\+[a-z0-9._%+-]+)?(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const FILES_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

enum Role {
  USER = 'USER',
  ADMIN = 'ADMIN',
  DISTRIBUTOR = 'DISTRIBUTOR',
}

const TOAST_CONFIG = {
  autoClose: 2000,
  position: 'top-center' as const,
};

const DIGITS_REGEX = /^[0-9]+$/;

const sortByRulesConsecutiveRepeatedDigits = (key: string): boolean => {
  for (let i = 0; i < key.length - 2; i++) {
    if (parseInt(key[i + 1]) === parseInt(key[i]) + 1 && parseInt(key[i + 2]) === parseInt(key[i + 1]) + 1) {
      return false;
    }
  }

  for (let i = 0; i < key.length - 2; i++) {
    if (key[i] === key[i + 1] && key[i + 1] === key[i + 2]) {
      return false;
    }
  }

  return true;
};

const decodeToken = (tokenBase64: string): IPinPayload => {
  const tokenJson = atob(tokenBase64);
  return JSON.parse(tokenJson);
};

const validateTokenLocally = (tokenBase64: string): boolean => {
  try {
    const { validBefore } = decodeToken(tokenBase64);
    if (typeof validBefore === 'number') {
      const currentTime = Date.now();
      return currentTime <= validBefore;
    }
    return false;
  } catch (error) {
    return false;
  }
};

enum ERROR_CODE {
  NotFound = 404,
  BadRequest = 400,
  Conflict = 409,
}
export {
  QUERIES,
  EMAIL_REGEX,
  UUID_REGEX,
  Role,
  DIGITS_REGEX,
  TOAST_CONFIG,
  FILES_TYPES,
  validateTokenLocally,
  sortByRulesConsecutiveRepeatedDigits,
  ERROR_CODE,
};
