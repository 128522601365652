import React from 'react';
import clsx from 'clsx';
import './spinner.styles.css';

interface IProp {
  className?: string;
  size?: string;
}

const Spinner: React.FC<IProp> = ({ className, size = '80px' }) => {
  return (
    <div className={clsx('lds-spinner', className)} style={{ '--spinner-size': size } as React.CSSProperties}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export { Spinner };
