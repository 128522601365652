import axios, { AxiosResponse } from 'axios';
import { AuthModel, IAuthResponse } from './_models';
import { environment } from '../../../config/env';
import { Response } from '../../../../_metronic/helpers';
const API_URL = environment.API_URL;
export const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh-token`;
const LOGIN_URL = `${API_URL}/auth/login`;
const LOGIN_CONFIRM = `${API_URL}/auth/login/callback`;
const GET_USER_BY_ACCESS_TOKEN = `${API_URL}/users/me`;

export const login = (email: string) => {
  return axios
    .post(LOGIN_URL, {
      destination: email,
    })
    .then((response: AxiosResponse<Response<IAuthResponse>>) => response.data);
};

export const loginConfirm = (token: string | null): Promise<AuthModel> => {
  return axios.get<AuthModel>(`${LOGIN_CONFIRM}?token=${token}`).then(response => {
    if (response?.data) {
      return response?.data;
    } else {
      throw new Error('Invalid response');
    }
  });
};

export const getUserByToken = (token: string | null) => {
  return axios.get(GET_USER_BY_ACCESS_TOKEN, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

export const updateAccessToken = async (refreshToken: string) => {
  return axios
    .post(REFRESH_TOKEN_URL, { refreshToken })
    .then((response: AxiosResponse<Response<AuthModel>>) => response.data)
    .then((response: Response<AuthModel>) => response.data);
};
