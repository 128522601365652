import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { UpdateCardPin } from '../modules/cardPIN/components/UpdateCardPIN';
import { App } from '../App';
import { Role } from '../config/consts';
import ViewCard from '../modules/viewCard/components/ViewCard';
const { PUBLIC_URL } = process.env;

const AppRoutes = () => {
  const { currentUser } = useAuth();
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='update-pin' element={<UpdateCardPin />} />
          <Route path='view-card' element={<ViewCard />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              {currentUser?.role == Role.DISTRIBUTOR ? (
                <Route index element={<Navigate to='/user' />} />
              ) : (
                <Route index element={<Navigate to='/dashboard' />} />
              )}
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
