import { useQuery } from 'react-query';
import { QUERIES } from '../../../config/consts';
import { getViewCardLink } from '../core/requests';
import { Spinner } from '../../../../_metronic/helpers/components/Spinner';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import AppLogo from '../../../../_metronic/assets/images/app-logo.svg';

const INITIAL_COUNT = 600;
const ViewCard = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const [showCard, setShowCard] = useState(false);
  const [count, setCount] = useState(INITIAL_COUNT);
  const { data: cardDetails, isFetching } = useQuery(
    QUERIES.CARD.VIEW_CARD,
    async () => {
      try {
        const response = await getViewCardLink({ token: token });
        setCount(response?.success ? INITIAL_COUNT : 0);
        return response;
      } catch (error) {
        console.error('Error fetching card details:', error);
        return { success: false };
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!token,
    }
  );
  useEffect(() => {
    if (count === 0) return;

    const countdown = setInterval(() => {
      setCount(prevCount => prevCount - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, [count]);

  return (
    <div className=' d-flex align-items-start justify-content-center h-100'>
      <div className='d-flex flex-column align-items-start justify-content-center h-100'>
        {isFetching && <Spinner className='fs-4' />}
        {!isFetching && cardDetails?.success && cardDetails?.accessUrl && count !== 0 ? (
          <div className='w-350px'>
            <div className='w-100 d-flex justify-content-between pt-20 px-3'>
              <h4 className='fs-4'>Card Details</h4>
              <span
                onClick={() => setShowCard(!showCard)}
                className='cursor-pointer'
                title={`${showCard ? 'Hide card' : 'Show card'}`}
              >
                <KTIcon iconName={`${showCard ? 'eye' : 'eye-slash'}`} className=' fs-1' />
              </span>
            </div>
            <div className={`${showCard ? '' : 'iframe-blur'}`}>
              <iframe title='card' src={cardDetails?.accessUrl || ''} className='w-350px h-250px' />
            </div>
          </div>
        ) : (
          ((cardDetails && !cardDetails?.success) || count === 0) && (
            <div
              className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
              }}
            >
              <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                <img alt='Logo' src={AppLogo} className='h-45px mb-12' />
                <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
                  <div className='text-center'>
                    <h1 className='fw-bolder text-danger mb-4'>Your link has expired</h1>
                    <div className='bg-light-info p-8 rounded'>
                      <div className='text-info'>The link you are trying to visit is expired.Please try again.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ViewCard;
