/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const DashboardPage = () => {
  return <h1>Dashboard page</h1>;
};

const DashboardWrapper = () => {
  return (
    <>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
