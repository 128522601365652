const AssignCardActiveIcon = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.3'
        d='M19.1268 5.21H5.66679C3.86082 5.21 2.39679 6.67403 2.39679 8.48V16.74C2.39679 18.546 3.86082 20.01 5.66679 20.01H19.1268C20.9328 20.01 22.3968 18.546 22.3968 16.74V8.48C22.3968 6.67403 20.9328 5.21 19.1268 5.21Z'
        fill='#172548'
      />
      <path d='M22.3968 8.94H2.39679V11.48H22.3968V8.94Z' fill='#172548' />
    </svg>
  );
};
export { AssignCardActiveIcon };
