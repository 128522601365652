const SweepFundsInActiveIcon = () => {
  return (
    <svg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.80619 1.93544V1.54615C3.80619 1.02322 3.3448 0.800335 3.12619 1.21769L0.0763749 4.67154C0.026813 4.76614 0 4.88145 0 5C0 5.11855 0.026813 5.23386 0.0763749 5.32846L3.12619 8.78231C3.3448 9.19966 3.80619 8.97678 3.80619 8.45385V8.06456C3.80619 7.94357 3.83954 7.82753 3.8989 7.74198C3.95826 7.65643 4.03877 7.60836 4.12271 7.60836H9.57241C9.80855 7.60836 10 7.33248 10 6.99215V3.00779C10 2.66746 9.80855 2.39158 9.57241 2.39158H4.12271C4.08115 2.39159 4.03999 2.37979 4.00158 2.35687C3.96319 2.33395 3.92829 2.30035 3.8989 2.258C3.8695 2.21564 3.84619 2.16535 3.83029 2.11001C3.81438 2.05467 3.80619 1.99534 3.80619 1.93544Z'
        fill='#A1A5B7'
      />
      <path
        d='M20.1938 9.93544V9.54615C20.1938 9.02323 20.6552 8.80033 20.8738 9.21769L23.9236 12.6715C23.9732 12.7662 24 12.8815 24 13C24 13.1185 23.9732 13.2338 23.9236 13.3285L20.8738 16.7823C20.6552 17.1996 20.1938 16.9768 20.1938 16.4539V16.0645C20.1938 15.9435 20.1605 15.8275 20.1011 15.7419C20.0418 15.6564 19.9612 15.6083 19.8773 15.6083H14.4276C14.1914 15.6083 14 15.3325 14 14.9922V11.0078C14 10.6675 14.1914 10.3916 14.4276 10.3916H19.8773C19.9188 10.3916 19.96 10.3798 19.9984 10.3569C20.0368 10.3339 20.0717 10.3004 20.1011 10.258C20.1305 10.2156 20.1538 10.1654 20.1698 10.11C20.1856 10.0547 20.1938 9.99535 20.1938 9.93544Z'
        fill='#A1A5B7'
      />
      <path
        d='M12.0001 16C15.866 16 19 12.866 19 8.99998C19 5.134 15.866 2 12.0001 2C8.13401 2 5 5.134 5 8.99998C5 12.866 8.13401 16 12.0001 16Z'
        fill='#E3E4E9'
      />
      <path
        d='M12.8865 8.45764C12.3434 8.26567 11.7423 8.03345 11.402 7.76656C11.28 7.67095 11.2281 7.46667 11.2697 7.24613C11.311 7.027 11.4589 6.76165 11.7503 6.67386C12.4337 6.46809 12.8764 6.8422 12.8949 6.85817L13.0759 7.00667C13.1799 7.09192 13.312 7.13518 13.4463 7.12794C13.5805 7.1207 13.7072 7.06349 13.8014 6.96756C14.0127 6.75244 14.0065 6.40605 13.7873 6.19902C13.5281 5.95414 13.1335 5.6712 12.614 5.56266V5.0435C12.614 4.97213 12.5999 4.90145 12.5726 4.83551C12.5453 4.76957 12.5052 4.70966 12.4548 4.65919C12.4043 4.60872 12.3444 4.56868 12.2784 4.54137C12.2125 4.51406 12.1418 4.5 12.0705 4.5C11.9991 4.5 11.9284 4.51406 11.8625 4.54137C11.7965 4.56868 11.7366 4.60872 11.6861 4.65919C11.6357 4.70966 11.5956 4.76957 11.5683 4.83551C11.541 4.90145 11.527 4.97213 11.527 5.0435V5.60691C11.4971 5.61507 11.4672 5.62358 11.4368 5.63301C10.8046 5.82941 10.3297 6.36496 10.2016 7.04487C10.0839 7.66913 10.2868 8.2734 10.7311 8.6219C11.1052 8.91525 11.6247 9.16463 12.5242 9.4826C13.1644 9.7089 13.1503 10.2284 13.1145 10.4413C13.0429 10.8674 12.6844 11.3279 12.0669 11.3321C11.4793 11.3359 11.2945 11.3066 10.84 11.0093C10.7753 10.967 10.7023 10.9389 10.6259 10.927C10.5496 10.9151 10.4715 10.9197 10.3971 10.9403C10.3226 10.961 10.2533 10.9974 10.194 11.0469C10.1346 11.0964 10.0865 11.1579 10.0529 11.2275C9.93041 11.4776 10.029 11.7789 10.2626 11.9305C10.7389 12.2394 11.0894 12.3597 11.527 12.4007V12.9633C11.527 13.0346 11.541 13.1054 11.5683 13.1713C11.5956 13.2373 11.6357 13.2971 11.6861 13.3476C11.7366 13.398 11.7965 13.4382 11.8625 13.4655C11.9284 13.4928 11.9991 13.5068 12.0705 13.5068C12.1418 13.5068 12.2125 13.4928 12.2784 13.4655C12.3444 13.4382 12.4043 13.398 12.4548 13.3476C12.5052 13.2971 12.5453 13.2373 12.5726 13.1713C12.5999 13.1054 12.614 13.0346 12.614 12.9633V12.3495C13.496 12.1214 14.0613 11.3663 14.1864 10.6214C14.3482 9.65924 13.8258 8.78968 12.8865 8.45764Z'
        fill='#ACB1C6'
      />
    </svg>
  );
};
export { SweepFundsInActiveIcon };
