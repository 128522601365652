const DistributorsActiveIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_38_891)'>
        <path
          d='M14.9828 9.72034C14.803 9.72034 14.623 9.65167 14.4857 9.51434C14.211 9.23969 14.211 8.79474 14.4857 8.52008L17.9987 5.05389C18.2734 4.77924 18.7183 4.77924 18.993 5.05389C19.2676 5.32855 19.2676 5.7735 18.993 6.04816L15.4799 9.51434C15.3426 9.65167 15.1628 9.72034 14.9828 9.72034Z'
          fill='#A1A5B7'
        />
        <path
          d='M19.216 17.6304C19.0622 17.6304 18.9076 17.5803 18.7786 17.4774L15.2252 14.6434C14.9209 14.4012 14.8715 13.959 15.1132 13.6554C15.3576 13.3513 15.7998 13.3026 16.1012 13.5435L19.6547 16.3773C19.9589 16.6197 20.0083 17.0619 19.7666 17.3653C19.6273 17.5398 19.4227 17.6304 19.216 17.6304Z'
          fill='#A1A5B7'
        />
        <path
          d='M12.7031 16.2188V19.0312C12.7031 19.4249 12.3939 19.7344 12 19.7344C11.6061 19.7344 11.2969 19.4249 11.2969 19.0312V16.2188C11.2969 15.8249 11.6061 15.5156 12 15.5156C12.3939 15.5156 12.7031 15.8249 12.7031 16.2188Z'
          fill='#A1A5B7'
        />
        <path
          d='M12.7031 16.2188V19.0312C12.7031 19.4249 12.3939 19.7344 12 19.7344V15.5156C12.3939 15.5156 12.7031 15.8249 12.7031 16.2188Z'
          fill='#172548'
        />
        <path
          d='M9.01721 9.7204C8.83722 9.7204 8.65741 9.65173 8.52008 9.5144L5.25989 6.25421C4.98523 5.97955 4.98523 5.53461 5.25989 5.25995C5.53455 4.98529 5.97949 4.98529 6.25415 5.25995L9.51434 8.52014C9.789 8.7948 9.789 9.23975 9.51434 9.5144C9.37701 9.65173 9.19702 9.7204 9.01721 9.7204Z'
          fill='#A1A5B7'
        />
        <path
          d='M7.78125 12.7031H3.51562C3.12708 12.7031 2.8125 12.3885 2.8125 12C2.8125 11.6115 3.12708 11.2969 3.51562 11.2969H7.78125C8.1698 11.2969 8.48438 11.6115 8.48438 12C8.48438 12.3885 8.1698 12.7031 7.78125 12.7031Z'
          fill='#A1A5B7'
        />
        <path
          d='M12.7031 3.51562V7.78125C12.7031 8.17493 12.3939 8.48438 12 8.48438C11.6061 8.48438 11.2969 8.17493 11.2969 7.78125V3.51562C11.2969 3.12177 11.6061 2.8125 12 2.8125C12.3939 2.8125 12.7031 3.12177 12.7031 3.51562Z'
          fill='#A1A5B7'
        />
        <path
          d='M12.7031 3.51562V7.78125C12.7031 8.17493 12.3939 8.48438 12 8.48438V2.8125C12.3939 2.8125 12.7031 3.12177 12.7031 3.51562Z'
          fill='#172548'
        />
        <path
          d='M2.10938 14.1094C0.946289 14.1094 0 13.1631 0 12C0 10.8369 0.946289 9.89062 2.10938 9.89062C3.27246 9.89062 4.21875 10.8369 4.21875 12C4.21875 13.1631 3.27246 14.1094 2.10938 14.1094Z'
          fill='#A1A5B7'
        />
        <path
          d='M12 0C10.8327 0 9.89062 0.942078 9.89062 2.10938C9.89062 3.27649 10.8327 4.21875 12 4.21875C13.1673 4.21875 14.1094 3.27649 14.1094 2.10938C14.1094 0.942078 13.1673 0 12 0Z'
          fill='#A1A5B7'
        />
        <path
          d='M20.4375 19.7344C19.2744 19.7344 18.3281 18.7881 18.3281 17.625C18.3281 16.4619 19.2744 15.5156 20.4375 15.5156C21.6006 15.5156 22.5469 16.4619 22.5469 17.625C22.5469 18.7881 21.6006 19.7344 20.4375 19.7344Z'
          fill='#A1A5B7'
        />
        <path
          d='M4.26562 7.07812C2.71454 7.07812 1.45312 5.81671 1.45312 4.26562C1.45312 2.71454 2.71454 1.45312 4.26562 1.45312C5.81671 1.45312 7.07812 2.71454 7.07812 4.26562C7.07812 5.81671 5.81671 7.07812 4.26562 7.07812Z'
          fill='#172548'
        />
        <path
          d='M20.4844 7.07812C18.546 7.07812 16.9688 5.50085 16.9688 3.5625C16.9688 1.62415 18.546 0.046875 20.4844 0.046875C22.4227 0.046875 24 1.62415 24 3.5625C24 5.50085 22.4227 7.07812 20.4844 7.07812Z'
          fill='#172548'
        />
        <path
          d='M12 18.3281C10.4531 18.3281 9.1875 19.5938 9.1875 21.1406C9.1875 22.6875 10.4531 24 12 24C13.5469 24 14.8125 22.6875 14.8125 21.1406C14.8125 19.5938 13.5469 18.3281 12 18.3281Z'
          fill='#172548'
        />
        <path
          d='M12 7.07812C9.28601 7.07812 7.07812 9.28601 7.07812 12C7.07812 14.714 9.28601 16.9219 12 16.9219C14.714 16.9219 16.9219 14.714 16.9219 12C16.9219 9.28601 14.714 7.07812 12 7.07812Z'
          fill='#172548'
        />
        <path
          d='M12 4.21875V0C13.1673 0 14.1094 0.942078 14.1094 2.10938C14.1094 3.27649 13.1673 4.21875 12 4.21875Z'
          fill='#A1A5B7'
        />
        <path
          d='M14.8125 21.1406C14.8125 22.6875 13.5469 24 12 24V18.3281C13.5469 18.3281 14.8125 19.5938 14.8125 21.1406Z'
          fill='#172548'
        />
        <path
          d='M16.9219 12C16.9219 14.714 14.714 16.9219 12 16.9219V7.07812C14.714 7.07812 16.9219 9.28601 16.9219 12Z'
          fill='#172548'
        />
      </g>
      <defs>
        <clipPath id='clip0_38_891'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { DistributorsActiveIcon };
