const AccountsActiveIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.875 20.25V20.25C15.1248 21.2498 15.7497 21.7497 15.7729 22.1983C15.7865 22.4607 15.6963 22.7178 15.5218 22.9142C15.2234 23.25 14.4232 23.25 12.8227 23.25H11.1773C9.57684 23.25 8.77659 23.25 8.47823 22.9142C8.30375 22.7178 8.21354 22.4607 8.22712 22.1983C8.25032 21.7497 8.87522 21.2498 10.125 20.25V20.25'
        fill='#172548'
      />
      <path
        d='M21.75 3.75H2.25C1.42157 3.75 0.75 4.42157 0.75 5.25V18.75C0.75 19.5784 1.42157 20.25 2.25 20.25H21.75C22.5784 20.25 23.25 19.5784 23.25 18.75V5.25C23.25 4.42157 22.5784 3.75 21.75 3.75Z'
        fill='#172548'
      />
      <path
        d='M21 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6V16.5C2.25 16.9142 2.58579 17.25 3 17.25H21C21.4142 17.25 21.75 16.9142 21.75 16.5V6C21.75 5.58579 21.4142 5.25 21 5.25Z'
        fill='#B2B8C5'
      />
      <path
        d='M11.625 18.75C11.625 18.5429 11.7929 18.375 12 18.375V18.375C12.2071 18.375 12.375 18.5429 12.375 18.75V18.75C12.375 18.9571 12.2071 19.125 12 19.125V19.125C11.7929 19.125 11.625 18.9571 11.625 18.75V18.75Z'
        fill='#172548'
      />
      <g clipPath='url(#clip0_185_456)'>
        <path
          opacity='0.3'
          d='M12.0002 15.1667C14.3013 15.1667 16.1668 13.3012 16.1668 11C16.1668 8.69885 14.3013 6.83337 12.0002 6.83337C9.69898 6.83337 7.8335 8.69885 7.8335 11C7.8335 13.3012 9.69898 15.1667 12.0002 15.1667Z'
          fill='#172548'
        />
        <path
          d='M12.0003 11.8084C12.8748 11.8084 13.5837 11.0995 13.5837 10.2251C13.5837 9.35061 12.8748 8.64172 12.0003 8.64172C11.1259 8.64172 10.417 9.35061 10.417 10.2251C10.417 11.0995 11.1259 11.8084 12.0003 11.8084Z'
          fill='#172548'
        />
        <path
          d='M12.0003 15.1667C12.9944 15.1678 13.9556 14.8114 14.7087 14.1625C14.5134 13.5997 14.1477 13.1117 13.6623 12.7663C13.1769 12.4209 12.596 12.2354 12.0003 12.2354C11.4046 12.2354 10.8237 12.4209 10.3383 12.7663C9.85295 13.1117 9.48723 13.5997 9.29199 14.1625C10.045 14.8114 11.0063 15.1678 12.0003 15.1667V15.1667Z'
          fill='#172548'
        />
      </g>
      <defs>
        <clipPath id='clip0_185_456'>
          <rect width='10' height='10' fill='white' transform='translate(7 6)' />
        </clipPath>
      </defs>
    </svg>
  );
};
export { AccountsActiveIcon };
